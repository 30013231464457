<template>
  <div class="content">
    <h1 id="v1">Tax ID Validation (Version 1 - Deprecated)</h1>

    <div class="notification is-link is-light">
      <b>Note:</b> The API was recently updated to
      <router-link :to="{ name: 'Validation' }">Version 2</router-link>.
    </div>

    <h3>Tax ID Number Validation Request</h3>

    <p>
      Tax ID Pro makes it easy to validate tax ID numbers for
      <router-link :to="{ name: 'Validation' }">over 100 countries</router-link>. A
      validation request includes:
    </p>
    <ul>
      <li>The tax ID number (TIN)</li>
      <li>A two-letter country code in IRS or ISO format</li>
      <li>
        Your API key (<router-link :to="{ name: 'Signup' }"
          >you can get one here!</router-link
        >)
      </li>
    </ul>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      https://api.taxid.pro/validate<br />
      <div style="padding-left: 18px">
        ? tin = <span class="has-text-warning">36574261809</span><br />
        &amp; iso_code = <span class="has-text-warning">de</span><br />
        &amp; key = <span class="has-text-warning">your_api_key</span>
      </div>
    </div>

    <!-- Example of validating by category -->

    <!-- <div class="box has-background-dark has-text-light" style="font-family: monospace">
  https://api.taxid.pro/tin/<span class="has-text-warning">36574261809</span>/category/<span class="has-text-warning">individual</span>/validate<br>
  <div style="padding-left: 18px;">
    ? iso_code = <span class="has-text-warning">de</span><br>
    &amp; key = <span class="has-text-warning">your_key</span>
  </div>
</div> -->

    <p>
      The <b>tin</b> may contain numbers and letters (uppercase or lowercase) dashes,
      underscores, and other special characters. Any character that is not a number or
      letter will be automatically removed during the validation process. Note that
      certain characters are not URL safe, and may cause http errors, so with the
      exception of dashes and underscores, we recommend cleaning your TIN prior to sending
      the request.
    </p>
    <p>
      The <b>irs_code</b> or <b>iso_code</b> should specify a two-letter IRS or ISO
      country code. Note that the two country code systems often use different
      abbreviations for the same country. Only one of the two formats should be used. See
      a complete listing of
      <a
        href="https://www.irs.gov/e-file-providers/foreign-country-code-listing-for-modernized-e-file"
        >IRS country codes</a
      >
      or
      <a href="https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"
        >ISO country codes</a
      >
      for reference.
    </p>
    <p>
      The API <b>key</b> should be kept secret like a password. Anyone with the API key
      can submit requests to the API. API access may be throttled if the system detects
      abuse. If you suspect that your key has been compromised, you can easily reset it by
      visiting your <router-link :to="{ name: 'Developer' }">API settings</router-link>.
    </p>

    <h3>TIN Validation Response</h3>

    <p>The response is an easily parseable JSON object:</p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "success": <span class="has-text-success">true</span>,<br />
        "results": {<br />
        <div style="padding-left: 18px">
          "individual": { <br />
          <div style="padding-left: 18px">
            "valid": <span class="has-text-success">true</span>, <br />
            "name": "Steuerliche Identifikationsnummer (Id. Nr.)", <br />
            "hint": "The German Id. Nr. has eleven digits." <br />
          </div>
          },<br />
          "entity": { <br />
          <div style="padding-left: 18px">
            "valid": <span class="has-text-danger">false</span>, <br />
            "name": "Steuernummer (St. Nr.)", <br />
            "hint": "The German St. Nr. has thirteen digits."<br />
          </div>
          },<br />
          "vat": { <br />
          <div style="padding-left: 18px">
            "valid": <span class="has-text-danger">false</span>,<br />
            "name": "Umsatzsteur Identifikationnummer (VAT)", <br />
            "hint": "The German VAT number has ten digits with an optional prefix of DE."
            <br />
          </div>
          },<br />
          "any_valid": <span class="has-text-success">true</span>,<br />
          "country": "Germany",<br />
          "irs_code": "GM",<br />
          "iso_code": "DE",<br />
          "tin": "36574261809"<br />
        </div>
        }<br />
      </div>
      }
    </div>

    <p>
      The <b>success</b> property indicates whether or not the <b>query itself</b> was
      successful. If the request was not successful, an error message will be provided.
      See details further down the page.
    </p>
    <p>
      The <b>results</b> object contains the results of the validation test, along with
      some other useful information. The TIN is checked against three different TIN
      categories: individual, entity, or VAT. The validation results are grouped
      accordingly.
    </p>

    <ul>
      <li><b>individual:</b> The validation results as they relate to individuals.</li>
      <ul>
        <li><b>valid:</b> Indicates the validity of the TIN for an individual.</li>
        <li><b>name:</b> The name of the TIN used for individuals.</li>
        <li><b>hint:</b> Formatting guidance, useful for correcting invalid TINs.</li>
      </ul>
      <li><b>entity:</b> The validation results as they relate to entities.</li>
      <ul>
        <li><b>valid:</b> Indicates the validity of the TIN for an entity.</li>
        <li><b>name:</b> The name of the TIN used for entities.</li>
        <li><b>hint:</b> Formatting guidance, useful for correcting invalid TINs.</li>
      </ul>
      <li><b>vat:</b> The validation results as they relate to VAT numbers.</li>
      <ul>
        <li><b>valid:</b> Indicates the validity of the VAT number.</li>
        <li><b>name:</b> The name of the VAT number.</li>
        <li><b>hint:</b> Formatting guidance, useful for correcting invalid VATs.</li>
      </ul>
      <li><b>any_valid:</b> Returns true if any of the above results are valid.</li>
      <li><b>country:</b> The full name of the country.</li>
      <li><b>irs_code:</b> The IRS code is included whether or not it was provided.</li>
      <li><b>iso_code:</b> The ISO code is included whether or not it was provided.</li>
      <li><b>tin_name:</b> The name of the tax identification system.</li>
      <li><b>tin:</b> A clean version of the TIN, special characters removed.</li>
    </ul>

    <p>
      In some cases, a category will not unavailable. For example, VAT numbers are not
      used in the United States. In these cases, the <b>valid</b> property will return
      <b>null</b> instead of true or false.
    </p>

    <p>If the response was not successful, an error message will be provided:</p>

    <div class="box has-background-dark has-text-light" style="font-family: monospace">
      {<br />
      <div style="padding-left: 18px">
        "success": <span class="has-text-danger">false</span>,<br />
        "error": "You must provide a country code."<br />
      </div>
      }
    </div>

    <p>
      It is recommended to check for success before performing additional operations with
      the response object.
    </p>
    <p>
      Sign up for a <router-link :to="{ name: 'Signup' }">free trial</router-link> and try
      it for yourself!
    </p>
    <br />
    <br />
    <br />
  </div>
</template>

<style scoped>
.param {
  font-family: monospace;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
.param-note {
  font-family: open-sans, sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.75rem;
}
.value {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 0.75rem;
  padding: 0 4px;
  font-family: monospace;
}
</style>

<script>
export default {
  name: 'ValidationV1',
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>