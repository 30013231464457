var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h1',{attrs:{"id":"v1"}},[_vm._v("Tax ID Validation (Version 1 - Deprecated)")]),_c('div',{staticClass:"notification is-link is-light"},[_c('b',[_vm._v("Note:")]),_vm._v(" The API was recently updated to "),_c('router-link',{attrs:{"to":{ name: 'Validation' }}},[_vm._v("Version 2")]),_vm._v(". ")],1),_c('h3',[_vm._v("Tax ID Number Validation Request")]),_c('p',[_vm._v(" Tax ID Pro makes it easy to validate tax ID numbers for "),_c('router-link',{attrs:{"to":{ name: 'Validation' }}},[_vm._v("over 100 countries")]),_vm._v(". A validation request includes: ")],1),_c('ul',[_c('li',[_vm._v("The tax ID number (TIN)")]),_c('li',[_vm._v("A two-letter country code in IRS or ISO format")]),_c('li',[_vm._v(" Your API key ("),_c('router-link',{attrs:{"to":{ name: 'Signup' }}},[_vm._v("you can get one here!")]),_vm._v(") ")],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_c('p',[_vm._v(" The API "),_c('b',[_vm._v("key")]),_vm._v(" should be kept secret like a password. Anyone with the API key can submit requests to the API. API access may be throttled if the system detects abuse. If you suspect that your key has been compromised, you can easily reset it by visiting your "),_c('router-link',{attrs:{"to":{ name: 'Developer' }}},[_vm._v("API settings")]),_vm._v(". ")],1),_c('h3',[_vm._v("TIN Validation Response")]),_c('p',[_vm._v("The response is an easily parseable JSON object:")]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',[_vm._v("If the response was not successful, an error message will be provided:")]),_vm._m(8),_c('p',[_vm._v(" It is recommended to check for success before performing additional operations with the response object. ")]),_c('p',[_vm._v(" Sign up for a "),_c('router-link',{attrs:{"to":{ name: 'Signup' }}},[_vm._v("free trial")]),_vm._v(" and try it for yourself! ")],1),_c('br'),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" https://api.taxid.pro/validate"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" ? tin = "),_c('span',{staticClass:"has-text-warning"},[_vm._v("36574261809")]),_c('br'),_vm._v(" & iso_code = "),_c('span',{staticClass:"has-text-warning"},[_vm._v("de")]),_c('br'),_vm._v(" & key = "),_c('span',{staticClass:"has-text-warning"},[_vm._v("your_api_key")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The "),_c('b',[_vm._v("tin")]),_vm._v(" may contain numbers and letters (uppercase or lowercase) dashes, underscores, and other special characters. Any character that is not a number or letter will be automatically removed during the validation process. Note that certain characters are not URL safe, and may cause http errors, so with the exception of dashes and underscores, we recommend cleaning your TIN prior to sending the request. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The "),_c('b',[_vm._v("irs_code")]),_vm._v(" or "),_c('b',[_vm._v("iso_code")]),_vm._v(" should specify a two-letter IRS or ISO country code. Note that the two country code systems often use different abbreviations for the same country. Only one of the two formats should be used. See a complete listing of "),_c('a',{attrs:{"href":"https://www.irs.gov/e-file-providers/foreign-country-code-listing-for-modernized-e-file"}},[_vm._v("IRS country codes")]),_vm._v(" or "),_c('a',{attrs:{"href":"https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes"}},[_vm._v("ISO country codes")]),_vm._v(" for reference. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"success\": "),_c('span',{staticClass:"has-text-success"},[_vm._v("true")]),_vm._v(","),_c('br'),_vm._v(" \"results\": {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"individual\": { "),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-success"},[_vm._v("true")]),_vm._v(", "),_c('br'),_vm._v(" \"name\": \"Steuerliche Identifikationsnummer (Id. Nr.)\", "),_c('br'),_vm._v(" \"hint\": \"The German Id. Nr. has eleven digits.\" "),_c('br')]),_vm._v(" },"),_c('br'),_vm._v(" \"entity\": { "),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-danger"},[_vm._v("false")]),_vm._v(", "),_c('br'),_vm._v(" \"name\": \"Steuernummer (St. Nr.)\", "),_c('br'),_vm._v(" \"hint\": \"The German St. Nr. has thirteen digits.\""),_c('br')]),_vm._v(" },"),_c('br'),_vm._v(" \"vat\": { "),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"valid\": "),_c('span',{staticClass:"has-text-danger"},[_vm._v("false")]),_vm._v(","),_c('br'),_vm._v(" \"name\": \"Umsatzsteur Identifikationnummer (VAT)\", "),_c('br'),_vm._v(" \"hint\": \"The German VAT number has ten digits with an optional prefix of DE.\" "),_c('br')]),_vm._v(" },"),_c('br'),_vm._v(" \"any_valid\": "),_c('span',{staticClass:"has-text-success"},[_vm._v("true")]),_vm._v(","),_c('br'),_vm._v(" \"country\": \"Germany\","),_c('br'),_vm._v(" \"irs_code\": \"GM\","),_c('br'),_vm._v(" \"iso_code\": \"DE\","),_c('br'),_vm._v(" \"tin\": \"36574261809\""),_c('br')]),_vm._v(" }"),_c('br')]),_vm._v(" } ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The "),_c('b',[_vm._v("success")]),_vm._v(" property indicates whether or not the "),_c('b',[_vm._v("query itself")]),_vm._v(" was successful. If the request was not successful, an error message will be provided. See details further down the page. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The "),_c('b',[_vm._v("results")]),_vm._v(" object contains the results of the validation test, along with some other useful information. The TIN is checked against three different TIN categories: individual, entity, or VAT. The validation results are grouped accordingly. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('b',[_vm._v("individual:")]),_vm._v(" The validation results as they relate to individuals.")]),_c('ul',[_c('li',[_c('b',[_vm._v("valid:")]),_vm._v(" Indicates the validity of the TIN for an individual.")]),_c('li',[_c('b',[_vm._v("name:")]),_vm._v(" The name of the TIN used for individuals.")]),_c('li',[_c('b',[_vm._v("hint:")]),_vm._v(" Formatting guidance, useful for correcting invalid TINs.")])]),_c('li',[_c('b',[_vm._v("entity:")]),_vm._v(" The validation results as they relate to entities.")]),_c('ul',[_c('li',[_c('b',[_vm._v("valid:")]),_vm._v(" Indicates the validity of the TIN for an entity.")]),_c('li',[_c('b',[_vm._v("name:")]),_vm._v(" The name of the TIN used for entities.")]),_c('li',[_c('b',[_vm._v("hint:")]),_vm._v(" Formatting guidance, useful for correcting invalid TINs.")])]),_c('li',[_c('b',[_vm._v("vat:")]),_vm._v(" The validation results as they relate to VAT numbers.")]),_c('ul',[_c('li',[_c('b',[_vm._v("valid:")]),_vm._v(" Indicates the validity of the VAT number.")]),_c('li',[_c('b',[_vm._v("name:")]),_vm._v(" The name of the VAT number.")]),_c('li',[_c('b',[_vm._v("hint:")]),_vm._v(" Formatting guidance, useful for correcting invalid VATs.")])]),_c('li',[_c('b',[_vm._v("any_valid:")]),_vm._v(" Returns true if any of the above results are valid.")]),_c('li',[_c('b',[_vm._v("country:")]),_vm._v(" The full name of the country.")]),_c('li',[_c('b',[_vm._v("irs_code:")]),_vm._v(" The IRS code is included whether or not it was provided.")]),_c('li',[_c('b',[_vm._v("iso_code:")]),_vm._v(" The ISO code is included whether or not it was provided.")]),_c('li',[_c('b',[_vm._v("tin_name:")]),_vm._v(" The name of the tax identification system.")]),_c('li',[_c('b',[_vm._v("tin:")]),_vm._v(" A clean version of the TIN, special characters removed.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" In some cases, a category will not unavailable. For example, VAT numbers are not used in the United States. In these cases, the "),_c('b',[_vm._v("valid")]),_vm._v(" property will return "),_c('b',[_vm._v("null")]),_vm._v(" instead of true or false. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box has-background-dark has-text-light",staticStyle:{"font-family":"monospace"}},[_vm._v(" {"),_c('br'),_c('div',{staticStyle:{"padding-left":"18px"}},[_vm._v(" \"success\": "),_c('span',{staticClass:"has-text-danger"},[_vm._v("false")]),_vm._v(","),_c('br'),_vm._v(" \"error\": \"You must provide a country code.\""),_c('br')]),_vm._v(" } ")])
}]

export { render, staticRenderFns }